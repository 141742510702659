/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
==========================================================================================*/

import helperActions from "./HelperActions";

import RegisterActions from "./auth/RegisterActions";
import ForgotPasswordActions from "./auth/ForgotPasswordActions";
import ConfirmActions from "./auth/ConfirmActions";
import ResetPasswordActions from "./auth/ResetPasswordActions";
import LoginActions from "./auth/LoginActions";
import LogoutActions from "./auth/LogoutActions";

import UserActions from "./user/UserActions";
import CountryActions from "./CountryActions";
import WarehouseActions from "./storage/WarehouseActions";
import StoreActions from "./storage/StoreActions";

const actions = Object.assign(
    helperActions,

    RegisterActions,
    ForgotPasswordActions,
    ConfirmActions,
    ResetPasswordActions,
    LoginActions,
    LogoutActions,

    UserActions,
    CountryActions,
    WarehouseActions,
    StoreActions
);

export default actions
