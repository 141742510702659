import axios from "axios";
import state from "../../state";

export default {
    /**
     *
     * @param commit
     * @param payload
     * @returns {Promise<unknown>}
     */
    saveWarehouse({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({url: state.API_URL + '/auth/warehouse-save', data: payload, method: 'POST'})
                .then(({data}) => {
                    let find_warehouse_index = state.user.warehouses.findIndex(warehouse => warehouse.id === data.warehouse.id);
                    if (find_warehouse_index >= 0) {
                        state.user.warehouses[find_warehouse_index] = data.warehouse;
                    } else {
                        state.user.warehouses.push(data.warehouse);
                    }

                    state.error_message = false;
                    state.success_message = data.message;
                    state.message_type = data.message_type ? data.message_type : 'system';

                    resolve();
                }).catch(({response}) => {
                reject(response.data);
            })
        });
    },

    /**
     *
     * @param commit
     * @param payload
     * @returns {Promise<unknown>}
     */
    setDefaultWarehouse({commit}, payload) {
        return new Promise(() => {
            axios({url: state.API_URL + '/auth/warehouse-set-default', data: payload, method: 'POST'})
                .then(({data}) => {
                    state.user.warehouses.map((warehouse) => {
                        if(warehouse.id === payload.id){
                            warehouse.default = 1;
                        } else {
                            warehouse.default = 0;
                        }
                    })

                    state.error_message = false;
                    state.success_message = data.message;
                    state.message_type = data.message_type ? data.message_type : 'system';
                }).catch(({response}) => {
                if (response.data.message) {
                    state.error_message = response.data.message;
                    state.success_message = false;
                    state.message_type = response.data.message_type ? response.data.message_type : 'system';
                }
            })
        });
    },

    /**
     *
     * @param commit
     * @param payload
     * @returns {Promise<unknown>}
     */
    deleteWarehouse({commit}, payload) {
        return new Promise(() => {
            axios({url: state.API_URL + '/auth/warehouse-delete/' + payload.id, method: 'DELETE'})
                .then(({data}) => {
                    let find_warehouse_index = state.user.warehouses.findIndex(warehouse => warehouse.id === payload.id);
                    if(find_warehouse_index >= 0){
                        state.user.warehouses.splice(find_warehouse_index, 1)
                    }

                    state.error_message = false;
                    state.success_message = data.message;
                    state.message_type = data.message_type ? data.message_type : 'system';
                }).catch(({response}) => {
                if (response.data.message) {
                    state.error_message = response.data.message;
                    state.success_message = false;
                    state.message_type = response.data.message_type ? response.data.message_type : 'system';
                }
            })
        });
    }
};
