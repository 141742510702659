/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store.js'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            // =============================================================================
            // BACKEND AUTH ROUTES
            // =============================================================================
            path: '/system',
            component: () => import('../layouts/system/System.vue'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    path: '/system/dashboard',
                    name: 'navigation.dashboard',
                    component: () => import('../views/system/pages/dashboard/Dashboard.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'navigation.dashboard', active: true},
                        ],
                        pageTitle: 'navigation.dashboard',
                    }
                },
                {
                    path: '/system/complete',
                    name: 'navigation.complete',
                    component: () => import('../views/system/pages/dashboard/Complete.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'navigation.complete', active: true},
                        ],
                        pageTitle: 'navigation.complete',
                    }
                },
                {
                    path: '/system/complete/warehouse',
                    name: 'navigation.complete.warehouse',
                    component: () => import('../views/system/pages/dashboard/CompleteWarehouse.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'navigation.complete.warehouse', active: true},
                        ],
                        pageTitle: 'navigation.complete.warehouse',
                    }
                },
                {
                    path: '/system/storage',
                    name: 'navigation.storage',
                    component: () => import('../views/system/pages/dashboard/Storage.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'navigation.storage', active: true},
                        ],
                        pageTitle: 'navigation.storage',
                    }
                },
                {
                    path: '/system/fulfillment',
                    name: 'navigation.fulfillment',
                    component: () => import('../views/system/pages/dashboard/Fulfillment.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'navigation.fulfillment', active: true},
                        ],
                        pageTitle: 'navigation.fulfillment',
                    }
                },
                {
                    path: '/system/storage/settings',
                    name: 'navigation.storage.settings',
                    component: () => import('../views/system/pages/storage/Settings.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'navigation.storage.settings', active: true},
                        ],
                        pageTitle: 'navigation.storage.settings',
                    }
                },
            ]
        },
        // =============================================================================
        // STATIC ROUTES
        // =============================================================================
        {
            path: '/auth/',
            component: () => import('../layouts/auth/Auth.vue'),
            children: [
                {
                    path: '/auth/login/:locale?',
                    name: 'page.login',
                    component: () => import('../views/front/pages/auth/Login.vue'),
                },
                {
                    path: '/auth/forgot-password/:locale?',
                    name: 'page.forgot_password',
                    component: () => import('../views/front/pages/auth/ForgotPassword.vue')
                },
                {
                    path: '/auth/register/:locale?',
                    name: 'page.register',
                    component: () => import('../views/front/pages/auth/Register.vue')
                },
                {
                    path: '/auth/confirm/:token/:locale?',
                    name: 'page.confirm',
                    component: () => import('../views/front/pages/auth/Confirm.vue')
                },
                {
                    path: '/auth/reset-password/:token/:locale?',
                    name: 'page.reset_password',
                    component: () => import('../views/front/pages/auth/ResetPassword.vue')
                }
            ]
        },
        // =============================================================================
        // STATIC FRONT ROUTES
        // =============================================================================
        {
            path: '/',
            component: () => import('../layouts/front/Front.vue'),
            children: [
                {
                    path: '/:locale?/:slug_one?/:slug_two?/:slug_three?/:slug_four?',
                    name: 'page',
                    component: () => import('../views/front/pages/public/Page.vue'),
                },
            ]
        }
    ],

    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
});

router.beforeEach((to, from, next) => {
    if (to.name === 'page') {
        next();
    } else {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (store.getters.isLoggedIn) {
                next();
            } else {
                next('/login');
            }
        } else {
            if (store.getters.isLoggedIn &&
                (
                    to.name === 'page.login' ||
                    to.name === 'page.forgot_password' ||
                    to.name === 'page.register' ||
                    to.name === 'page.confirm' ||
                    to.name === 'page.reset_password'
                )
            ) {
                next('/system/dashboard');
            } else {
                next();
            }
        }
    }
});

export default router
