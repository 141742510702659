/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
==========================================================================================*/


const getters = {
    // ////////////////////////////////////////////
    // AUTH
    // ////////////////////////////////////////////
    isLoggedIn: state => state.token,
};

export default getters
