const translations = {
    'title': "Reset password",
    'description': "Reset password",
    'back_to_login': "Back to Login",
    'reset_password': "Reset password",

    'fields.new_password': "New password",

    'message.new_password_is_required': "Password is required",
    'message.new_password_min': "Password min length is 6 characters"
}

export default translations
