/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
==========================================================================================*/

import translations from './translations/translations'

const state = {
    APP_NAME: "EZSTOK",
    APP_URL: process.env.MIX_APP_URL,
    API_URL: process.env.MIX_API_URL,

    success_message: false,
    error_message: false,
    message_type: false,

    currentTranslations: translations,

    user: null,
    token: localStorage.getItem('access-token') || '',
    // token: '',

    languages: [
        {name: 'english', code: 'en', default: true, active: true},
        {name: 'latvian', code: 'lv', default: false, active: false},
        {name: 'russian', code: 'ru', default: false, active: false},
    ],
    language: {name: 'english', code: 'en', default: true},
};

export default state
