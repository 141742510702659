import axios from "axios";
import state from "../../state";

export default {
    /**
     * @param commit
     * @param payload
     * @returns {Promise<unknown>}
     */
    user({commit}) {
        return new Promise((resolve, reject) => {
            axios({url: state.API_URL + '/auth/user', method: 'GET'})
                .then(({data}) => {
                    state.user = data.user;

                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response.data);
            });
        })
    },

    /**
     *
     * @param commit
     * @param payload
     * @returns {Promise<unknown>}
     */
    completePersonalInformation({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({url: state.API_URL + '/auth/complete-personal-information', data: payload, method: 'POST'})
                .then(({data}) => {
                    state.user.profile = data.profile;

                    resolve(data);
                }).catch(({response}) => {
                reject(response.data);
            })
        });
    },

    /**
     *
     * @param commit
     * @param payload
     * @returns {Promise<unknown>}
     */
    completeWarehouseInformation({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({url: state.API_URL + '/auth/complete-warehouse-information', data: payload, method: 'POST'})
                .then(({data}) => {
                    state.user.warehouses = data.warehouses;

                    resolve(data);
                }).catch(({response}) => {
                reject(response.data);
            })
        });
    },
};
