/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
==========================================================================================*/


import Vue from 'vue'

//root vue file
import App from './App.vue'

//Axios
import Axios from 'axios'

// Vue Router
import router from './routes/router'

// Vuex Store
import store from './store/store'

// import Echo from "laravel-echo"
// window.io = require('socket.io-client');
// window.Echo = new Echo({
//     broadcaster: 'socket.io',
//     host: window.location.hostname + ":6002"
// });

Vue.prototype.$http = Axios;
const token = localStorage.getItem('access-token');
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

import VueSocialauth from 'vue-social-auth'
Vue.use(VueSocialauth, {
    providers: {
        facebook: {
            clientId: '694831664862768',
            redirectUri: process.env._APP_URL + '/auth/facebook/callback' // Your client app URL
        },
        google: {
            clientId: '97804663028-f07a2tpkbj24hkf8d0n96nun0kosf72e.apps.googleusercontent.com',
            redirectUri: process.env._APP_URL + '/auth/google/callback' // Your client app URL
        }
    }
})

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
