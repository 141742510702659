<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
========================================================================================== -->
<template>
    <router-view></router-view>
</template>

<script>
    export default {
        data() {
            return {}
        },
        beforeCreate() {

        }
    }
</script>
