import axios from "axios";
import state from "../../state";

export default {
    /**
     * @param commit
     * @param payload
     * @returns {Promise<unknown>}
     */
    platforms({commit}) {
        return new Promise((resolve) => {
            axios({url: state.API_URL + '/auth/platforms', method: 'GET'})
                .then(({data}) => {
                    resolve(data);
                });
        })
    },

    /**
     *
     * @param commit
     * @param payload
     * @returns {Promise<unknown>}
     */
    saveStore({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios({url: state.API_URL + '/auth/store-save', data: payload, method: 'POST'})
                .then(({data}) => {
                    let find_store_index = state.user.stores.findIndex(store => store.id === data.store.id);
                    if (find_store_index >= 0) {
                        state.user.stores[find_store_index] = data.store;
                    } else {
                        state.user.stores.push(data.store);
                    }

                    state.error_message = false;
                    state.success_message = data.message;
                    state.message_type = data.message_type ? data.message_type : 'system';

                    resolve();
                }).catch(({response}) => {
                reject(response.data);
            })
        });
    },

    /**
     *
     * @param commit
     * @param payload
     * @returns {Promise<unknown>}
     */
    setDefaultStore({commit}, payload) {
        return new Promise(() => {
            axios({url: state.API_URL + '/auth/store-set-default', data: payload, method: 'POST'})
                .then(({data}) => {
                    state.user.stores.map((store) => {
                        if (store.id === payload.id) {
                            store.default = 1;
                        } else {
                            store.default = 0;
                        }
                    })

                    state.error_message = false;
                    state.success_message = data.message;
                    state.message_type = data.message_type ? data.message_type : 'system';
                }).catch(({response}) => {
                if (response.data.message) {
                    state.error_message = response.data.message;
                    state.success_message = false;
                    state.message_type = response.data.message_type ? response.data.message_type : 'system';
                }
            })
        });
    },

    /**
     *
     * @param commit
     * @param payload
     * @returns {Promise<unknown>}
     */
    updateStoreInformation({commit}, payload) {
        return new Promise(() => {
            axios({url: state.API_URL + '/auth/store-update-information', data: payload, method: 'POST'})
                .then(({data}) => {
                    console.log(data);
                    state.error_message = false;
                    state.success_message = data.message;
                    state.message_type = data.message_type ? data.message_type : 'system';
                }).catch(({response}) => {
                console.log(response);
                if (response.data.message) {
                    state.error_message = response.data.message;
                    state.success_message = false;
                    state.message_type = response.data.message_type ? response.data.message_type : 'system';
                }
            })
        });
    },

    /**
     *
     * @param commit
     * @param payload
     * @returns {Promise<unknown>}
     */
    deleteStore({commit}, payload) {
        return new Promise(() => {
            axios({url: state.API_URL + '/auth/store-delete/' + payload.id, method: 'DELETE'})
                .then(({data}) => {
                    let find_store_index = state.user.stores.findIndex(store => store.id === payload.id);
                    if (find_store_index >= 0) {
                        state.user.stores.splice(find_store_index, 1)
                    }

                    state.error_message = false;
                    state.success_message = data.message;
                    state.message_type = data.message_type ? data.message_type : 'system';
                }).catch(({response}) => {
                if (response.data.message) {
                    state.error_message = response.data.message;
                    state.success_message = false;
                    state.message_type = response.data.message_type ? response.data.message_type : 'system';
                }
            })
        });
    }
};
